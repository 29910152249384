
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 提货补贴
 */
export default defineComponent({
  name: 'PurchaseBonus',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'userId',
              label: '代理',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'month',
              label: '月份',
              type: 'month'
            },
          ]
        },
        remote: {
          action: https?.payment.pagePurchaseBonus
        }
      },
      cols: [
        {
          field: 'orderCode',
          label: '订单号',
          ability: 'copy',
          width: 240
        },
        {
          field: 'userId',
          label: '代理ID'
        },
        {
          field: 'userPhone',
          label: '手机号'
        },
        {
          field: 'realName',
          label: '姓名',
          width: 120
        },
        {
          field: 'transAmt',
          label: '交易总额',
          width: 100
        },
        {
          field: 'num',
          label: '购机数量',
          width: 100
        },
        {
          field: 'ratio',
          label: '补贴比率',
          width: 100
        },
        {
          field: 'bonus',
          label: '补贴金额',
          width: 100
        },
        {
          field: 'month',
          label: '月份',
          width: 100
        },
        {
          field: 'statusDesc',
          label: '状态',
          width: 100
        },
        {
          field: 'remark',
          label: '备注',
          width: 140
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 160
        },
        {
          field: 'updateTime',
          label: '修改时间',
          width: 160
        }
      ]
    };
    return { tableOpts };
  }
});
